
import EuLogoColor from '../../assets/images/eulogocolor.png';
import EuLogoWhite from '../../assets/images/eulogowhite.png';

import {Row, Col} from 'reactstrap';

export function EuLogo() {
    return <div>
        <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
                <img className="img-fluid mt-4 mb-3 d-block mx-auto" style={{ width: '70%' }} src={EuLogoColor} alt="" />
            </Col>
        </Row>
        <p className="text-center">This project has been funded with the support of the European Commission. The content of this website represents the views of the authors only and is their sole responsibility. The Commission is not responsible for any use that may be made of the information contained therein.</p>
    </div>;
}

export function EuLogoMenu() {
    return <div>
        <img className="img-fluid d-block mx-auto" style={{ width: '60%' }} src={EuLogoWhite} alt="" />
    </div>;
}