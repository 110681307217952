
import React from 'react';

import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import { injectIntl } from 'react-intl';

export default class extends React.Component {
    render() {
        if (!this.props.value)
            return null;

        return <HlsPlayer link={this.props.value.video.hls_link} {...this.props} />;
    }
}

const HlsPlayer = injectIntl(class extends React.Component {
    state = { reported: false };

    video = React.createRef();

    handleEnd(e) {
        this.props.onFinish && this.props.onFinish();
    }
    
    componentDidMount() {
        this.mounted = true;
        var video = this.video.current;

        var that = this;
        video.addEventListener("timeupdate", function(e) {
            if(!that.state.reported) {
              var cTime = this.currentTime;
              var perc = cTime * 100 / this.duration;
              if(perc > 90) {
                that.state.reported = true;
                that.handleEnd(e);
              }
            }
        });

        if (video.textTracks[0]) {
            video.textTracks[0].mode = 'showing';
        }
          
        if (Hls.isSupported()) {
            this.hls = new Hls();
            this.hls.loadSource(this.props.link);
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                if (this.mounted) {
                    video.play();
                }
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.props.link;
            video.addEventListener('loadedmetadata', function() {
                video.play();
            });
        }

        this.plyr = new Plyr(video, {
            captions: {
                active: true,
                language: this.props.intl.locale
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        this.hls && this.hls.destroy();
        this.plyr.destroy();
    }

    render() {
        return <div className="video-padding">
            <video ref={this.video} crossOrigin="anonymous">
                { this.props.subtitle && <track kind="subtitles" srcLang={this.props.intl.locale} label="Subtitle" default src={this.props.subtitle} /> }
            </video>
        </div>;
    }
});

export class AutoPlayHls extends React.Component {
    video = React.createRef();

    componentDidMount() {
        var video = this.video.current;

        if (Hls.isSupported()) {
            var hls = new Hls();
            hls.loadSource(this.props.link);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function() {
                video.play();
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.props.link;
            video.addEventListener('loadedmetadata', function() {
                video.play();
            });
        }
    }

    componentWillUnmount() {
        this.video.current.pause();
    }

    render() {
        return <video muted={this.props.muted} ref={this.video} width={this.props.width} height={this.props.height}>
            { this.props.subtitle && <track kind="captions" default src={this.props.subtitle} /> }
        </video>;
    }
}
