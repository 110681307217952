import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                    <footer className="footer">
                        <Container fluid>
                            <Row>
                                <Col sm="12">
                                    <FormattedMessage id="COPYRIGHT" values={{ year: new Date().getFullYear() }} />
                                </Col>
                            </Row>
                            <Container>
                                <p className="text-center text-dark pb-0" style={{ fontSize: '.75em' }}>This project has been funded with the support of the European Commission. The content of this website represents the views of the authors only and is their sole responsibility. The Commission is not responsible for any use that may be made of the information contained therein.</p>
                            </Container>
                        </Container>

                    </footer>
            </React.Fragment>
        );
    }
}

export default Footer;